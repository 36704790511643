@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Définition des variables CSS pour les couleurs */
  :root {
    --primary: #8448ff; /* Violet principal */
    --secondary: #4300FF; /* Bleu secondaire */
    --foreground: #1D1D1D; /* Texte principal */
    --inverse-foreground: #FFFFFF; /* Texte inverse */
    --background: #FFFFFF; /* Fond principal */
    --muted: #8448ff; /* Couleur atténuée (jaune) */
    --muted-foreground: #E2EF0F;

    /* Variantes de couleurs */
    --violet-light: #e5d7ff;
    --violet-med-light: #c2a7ff;
    --violet-less-light: #a87bff;
    --violet: #8448ff;
    --violet-less-dark: #6936d6;
    --violet-med-dark: #5027b2;
    --violet-dark: #3c1c8c;

    --blue-light: #e0e0ff;
    --blue-med-light: #a8a8ff;
    --blue-less-light: #746bff;
    --blue: #4300FF;
    --blue-less-dark: #3200cc;
    --blue-med-dark: #23007A;
    --blue-dark: #19005d;

    --grey-dark: #1D1D1D;
  }

  /* Thème sombre */
  .dark {
    --foreground: #FFFFFF; /* Texte en mode sombre */
    --inverse-foreground: #1D1D1D; /* Texte inverse sombre */
    --background: #1D1D1D; /* Fond en mode sombre */
    --muted-foreground: #E2EF0F; /* Jaune pour accents en sombre */
    --muted: #E2EF0F; /* Couleur atténuée (jaune) */
  }

  /* Styles globaux */
  html, body {
    overflow-x: hidden;
  }

  #nav a:hover {
    font-weight: bolder;
    color: var(--muted-foreground);
  }
}

@layer components {
  /* Boutons */
  .cta-transparent {
    background-color: transparent; /* Fond transparent */
    border: 2px solid #007BFF; /* Bordure bleue */
    padding: 12px 24px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .light .cta-transparent {
    border-color: #007BFF;
    color: #4A90E2;
  }

  .dark .cta-transparent {
    border-color: #8448ff;
    color: #8448ff;
  }

  .cta {
    background-color: transparent;
    padding: 12px 24px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .light .cta {
    background-color: var(--blue);
    color: var(--inverse-foreground);
  }

  .dark .cta {
    background-color: var(--primary);
    color: var(--inverse-foreground);
  }

  /* Styles pour les balises <b> */
  .light b {
    color: var(--blue-less-light);
  }

  .dark b {
    color: var(--muted-foreground);
  }
}

@layer utilities {
  /* Texte dégradé */
  .gradient-text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg, var(--primary), var(--blue-less-light));
  }  

  /* Texte stylé */
  .text-studio {
    transition: color 0.3s ease;
  }

  .light .text-studio {
    color: var(--blue-less-light);
  }

  .dark .text-studio {
    color: var(--muted-foreground);
  }

  .text-purp {
    color: var(--blue-less-light);
    font-weight: bold;
  }

  /* Icônes thématiques */
  .icon-themed {
    transition: color 0.3s ease;
  }

  .light .icon-themed {
    color: var(--primary);
  }

  .dark .icon-themed {
    color: var(--muted-foreground);
  }
}

/* Masquer le badge reCAPTCHA */
.grecaptcha-badge {
  visibility: hidden;
}
