@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .ham {
    @apply cursor-pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #000;
    stroke-width: 4;
    stroke-linecap: round;
  }

  .dark .line {
    stroke: #fff; /* Couleur des lignes en mode sombre */
  }

  .ham3 .top {
    stroke-dasharray: 40 130;
  }
  .ham3 .middle {
    stroke-dasharray: 40 140;
  }
  .ham3 .bottom {
    stroke-dasharray: 40 205;
  }
  .ham3.active .top {
    stroke-dasharray: 75 130;
    stroke-dashoffset: -63px;
  }
  .ham3.active .middle {
    stroke-dashoffset: -102px;
  }
  .ham3.active .bottom {
    stroke-dasharray: 110 205;
    stroke-dashoffset: -86px;
  }
}
